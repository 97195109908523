<!--我的周报-->
<template lang="html">
    <div class="commonWrap">
        <el-row v-if="isShowMenu" type="flex" justify="space-between" class="fixMenu" style="border-color:#006666">
            <el-tooltip class="item" effect="dark" content="排序向上" placement="bottom">
                <el-button type="text" :disabled="sortUpDistable"  icon="el-icon-top" @click="sortUp()"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="排序向下" placement="bottom">
                <el-button type="text" :disabled="sortDownDistable" icon="el-icon-bottom" @click="sortDown()"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="结转到下周" placement="bottom">
                <el-button type="text" :disabled="nextWeekDistable" icon="el-icon-refresh-right" @click="nextWeek()"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="设置优先级" placement="bottom">
                <el-button type="text" :disabled="delDistable" icon="el-icon-warning-outline" @click="setPriority()"></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="删除" placement="bottom">
                <el-button type="text" icon="el-icon-delete" @click="handleDel()"></el-button>
            </el-tooltip>
        </el-row>

        <div class="weekBox">
            <el-row style="margin-bottom:15px;" type="flex" justify="space-between">
                <el-button v-if="userId" style="width:150px" type="warning" size="small" plain icon="el-icon-sort" @click="jumpMonth()">切换月报</el-button>
            </el-row>

            <el-collapse v-model="selectWeek">
                <el-card v-for="(item,index) in weekArray" :key="index" style="margin-bottom:10px">
                    <el-collapse-item  :name="index">
                        <template slot="title">
                            <el-row type="flex" justify="space-between" align="middle" style="width:100%;padding-right:10px;">
                                <div class="title" v-if="!selectWeek.includes(index)">第{{item.week_num}}周
									<span style="font-size:15px"> {{item.begin_time}} - {{item.end_time}}</span>
								</div>
                                <div v-if="index < showCount" :class="[item.review == 2 ? 'topPlanActive' : 'reviewColor']">{{item.status == 0 && item.review != 2 ? '周报未提交' : item.review == 1 ? '审核已通过' : item.review == 0 ?'周报暂未审核' : item.refuse_reason}}</div>
                                
								<!--上级查看下级进行审核-->
								<div v-if="index < showCount">
	                                <el-button v-if="userId && isWeek == 2 && item.review !=1 && item.status == 1" type="primary" style="margin-left:auto" size="small" plain icon="el-icon-check" @click.stop="acceptCheck(item)">审核通过</el-button>
	                                <el-button v-if="userId && isWeek == 2 && item.review !=1 && item.status == 1" type="danger" size="small" plain icon="el-icon-close" @click.stop="refuseCheck(item)">审核拒绝</el-button>
	                                <el-button v-if="!userId && item.status == 0" v-intro="'填写完成后点击提交周报进行提交审核'" v-intro-step="7" v-intro-if="index == 0" type="primary" style="margin-left:auto;" size="small" :disabled="item.done.length > 0 && item.plan.length > 0 ? false : true" icon="el-icon-check" @click.stop="submit(item)">提交周报</el-button>
								</div>
                            </el-row>
                        </template>

                        <el-row>
                            <!--本周总结-->
                            <el-row class="tipBox flex flex-ai-c">
								<p class="title">第{{item.week_num}}周总结</p>
                                <b class="b_weekText">{{item.begin_time}} - {{item.end_time}}</b>
                            </el-row>

                            <el-table :data="item.done" ref="done" highlight-current-row border :cell-class-name="cellStyle"  :row-class-name="tableRowAddIndex" @row-click="(row,column,e)=>rowClick(row,column,e,index,'done')" :header-cell-style="{background:'#f5f5f5',color:'#515A6E'}" :span-method="item.doneColspan">
                                <el-table-column label="序号" width="50" align="center">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.id != 0">{{scope.$index+1}}</div>
                                        <div v-else style="height:20px"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="岗位职责" width="200" show-overflow-tooltip>
                                    <template slot-scope="scope">
										<div v-intro="'请选择岗位职责'" v-intro-step="1" v-intro-if="index == 0 && scope.$index==0">
											<el-select class="typeWidth" v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0 && !scope.row.plan_id) || (userId && item.review !=1) || (scope.row.add_status==2))" v-model="scope.row.class_name" allow-create size="small" filterable placeholder="岗位职责" @change="selectTypeBlur(scope.row,scope.row.class_name,'done',index)">
												<el-option v-for="(item,tIndex) in typeList" :key="tIndex" :label="item.name" :value="item.name"></el-option>
											</el-select>
											<el-row v-else>
                                                <el-popover placement="top" width="200" trigger="click" :content="scope.row.class_content">
                                                    <el-row slot="reference">{{scope.row.class_name}}</el-row>
                                                </el-popover>
											</el-row>
										</div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="工作安排">
                                    <template slot-scope="scope">
										<div v-intro="'请填写工作安排'" v-intro-step="2" v-intro-if="index == 0 && scope.$index==0">
											<el-input ref="allinput" v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0 && !scope.row.plan_id) || (userId && item.review !=1))" v-model="scope.row.content" type="textarea" :autosize="{ minRows:1}" resize="none"  @blur="save(scope.row,scope.row.content)" size="small" placeholder="工作安排"></el-input>
											<el-row v-else>{{scope.row.content}}</el-row>
										</div>
                                    </template>
                                </el-table-column>
								<el-table-column label="结果定义">
                                    <template slot-scope="scope">
										<!-- && !scope.row.plan_id 新加 -->
										<div v-intro="'请填写结果定义'" v-intro-step="3" v-intro-if="index == 0 && scope.$index==0">
	                                        <el-input ref="allinput" v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0 && !scope.row.plan_id) || (userId && item.review !=1) || (scope.row.add_status==2))" v-model="scope.row.plan_result" type="textarea" :autosize="{ minRows:1}" resize="none"  @blur="save(scope.row,scope.row.plan_result)" size="small" placeholder="结果定义"></el-input>
	                                        <el-row v-else>{{scope.row.plan_result}}</el-row>
										</div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="name" label="计划完成" width="200" show-overflow-tooltip>
                                    <template slot-scope="scope" v-if="scope.row.id != 0">
										<div v-intro="'请设置计划完成时间'" v-intro-step="4" v-intro-if="index == 0 && scope.$index==0">
											<div class="flex weekPlanFinish" v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0 && !scope.row.plan_id) || (userId && item.review !=1) || (scope.row.add_status==2))">
												<p v-for="(date,dateIndex) in weekArr" :class="[scope.row.plan_type == 1 && date.value == scope.row.plan_real ? 'cur' : '']"  @click="yujiDate(1,scope.$index,date.value)">{{date.name}}</p>
											</div>
											<div class="flex weekPlanFinish" v-else>
												<p :class="[scope.row.plan_type == 1 && date.value == scope.row.plan_real ? 'cur' : '']" v-for="(date,dateIndex) in weekArr">{{date.name}}</p>
											</div>
										</div>
                                    </template>
                                </el-table-column>
                                <!--<el-table-column prop="name" label="实际完成" width="130" show-overflow-tooltip>
                                    <template slot-scope="scope">
										<div v-intro="'请设置实际完成时间'" v-intro-step="5" v-intro-if="index == 0 && scope.$index==0">
											<el-popover v-model="scope.row.popFinishVisible" v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1) || (scope.row.add_status==2))" placement="bottom" title="实际完成进度" width="320" trigger="click">
												<div class="week_popover">
													<div v-for="fItem in weekFinishDate" class="flex flex-wrap finishDate">
														<label>{{fItem.title}}：</label>
														<div v-if="fItem.id != 2">
															<el-date-picker v-if="fItem.id == 5" v-model="scope.row.zhuan_date" value-format="yyyy-MM-dd" format="yyyy年MM月dd日" style="margin-left:10px" size="small" type="date" placeholder="选择日期" @blur="planSelectDate(scope.row)"></el-date-picker>
															<span v-else v-for="date in fItem.arr" :class="[(scope.row.done_type == 1 || scope.row.done_type == 3 || scope.row.done_type == 4) && date.name == scope.row.done_real ? 'selectActive' : '']"  @click="finishDate(date.id,scope.$index,date.name)">{{date.name}}</span>
														</div>
														<div v-else style="padding-left:10px">
															已完成
															<el-select v-model="progress" size="small"  placeholder="进度" style="width:80px" @change="scope.row.done_real = progress;scope.row.done_type=2;scope.row.popFinishVisible=false;save(scope.row,scope.row.done_type)">
																<el-option v-for="item in fItem.arr" :key="item" :label="item" :value="item"></el-option>
															</el-select>
															，下周继续
														</div>
													</div>
												</div>
												<el-input v-model="scope.row.done_real"  readonly size="small" placeholder="实际完成" slot="reference" @focus="scope.row.done_type == 2 ? progress = scope.row.done_real : progress = '';scope.row.done_type == 5 ? scope.row.zhuan_date=scope.row.done_real :scope.row.zhuan_date= '' "></el-input>
											</el-popover>
											<el-row v-else>{{scope.row.done_real}}</el-row>
										</div>
                                    </template>
                                </el-table-column>-->
                                <el-table-column label="完成情况">
                                    <template slot-scope="scope">
										<div v-intro="'请填写完成情况'" v-intro-step="6" v-intro-if="index == 0 && scope.$index==0">
	                                        <el-input v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1) || (scope.row.add_status==2))" v-model="scope.row.remark" @blur="save(scope.row,scope.row.remark)" type="textarea" :autosize="{ minRows:1}" resize="none" size="small" placeholder="完成情况说明"></el-input>
	                                        <el-row v-else>{{scope.row.remark}}</el-row>
										</div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="未完成原因">
                                    <template slot-scope="scope">
										<div v-intro="'请填写未完成原因'" v-intro-step="7" v-intro-if="index == 0 && scope.$index==0">
	                                        <el-input v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1) || (scope.row.add_status==2))" v-model="scope.row.undone" @blur="save(scope.row,scope.row.undone)" type="textarea" :autosize="{ minRows:1}" resize="none" size="small" placeholder="未完成原因"></el-input>
	                                        <el-row v-else>{{scope.row.undone}}</el-row>
										</div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="解决措施">
                                    <template slot-scope="scope">
										<div v-intro="'请填写解决措施'" v-intro-step="8" v-intro-if="index == 0 && scope.$index==0">
	                                        <el-input v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1) || (scope.row.add_status==2))" v-model="scope.row.solve" @blur="save(scope.row,scope.row.solve)" type="textarea" :autosize="{ minRows:1}" resize="none" size="small" placeholder="解决措施"></el-input>
	                                        <el-row v-else>{{scope.row.solve}}</el-row>
										</div>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <!--下周计划-->
                            <el-row class="tipBox flex flex-ai-c" style="margin-top:40px">
								<p class="title">第{{item.week_num+1}}周计划</p>
                                <b>{{item.plan_begin_time}} - {{item.plan_end_time}}</b>
                            </el-row>

                            <el-table :data="item.plan" ref="plan" border style="width: 100%" highlight-current-row :cell-class-name="cellStyle" :row-class-name="tableRowAddIndex" @row-click="(row,column,e)=>rowClick(row,column,e,index,'plan')"  :header-cell-style="{background:'rgba(0,134,133,0.1)',borderColor:'#e5e5e5',color:'#515A6E'}" :span-method="item.planColspan">
                                <el-table-column label="序号" width="50" align="center">
                                    <template slot-scope="scope">
                                        <div v-if="scope.row.id != 0">{{scope.$index+1}}</div>
                                        <div v-else style="height:20px"></div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="岗位职责" width="200" show-overflow-tooltip>
                                    <template slot-scope="scope">
                                        <el-select v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1))" allow-create v-model="scope.row.class_name" size="small" filterable placeholder="岗位职责" @change="selectTypeBlur(scope.row,scope.row.class_name,'plan',index)" style='width:100%'>
                                            <el-option v-for="(item,tIndex) in typeList" :key="'s'+tIndex" :label="item.name" :value="item.name"></el-option>
                                        </el-select>
                                        <el-row v-else>
                                            <el-popover placement="top" width="200" trigger="click" :content="scope.row.class_content">
                                                <el-row slot="reference">{{scope.row.class_name}}</el-row>
                                            </el-popover>
                                        </el-row>
                                    </template>
                                </el-table-column>
                                <el-table-column label="工作安排">
                                    <template slot-scope="scope">
                                        <el-input v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1))" v-model="scope.row.content" type="textarea" :autosize="{ minRows:1}" resize="none" @blur="save(scope.row,scope.row.content)" size="small" placeholder="工作安排"></el-input>
                                        <el-row v-else>{{scope.row.content}}</el-row>
                                    </template>
                                </el-table-column>
                                <el-table-column label="结果定义">
                                    <template slot-scope="scope">
                                        <el-input v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1))" v-model="scope.row.plan_result" type="textarea" :autosize="{ minRows:1}" resize="none" @blur="save(scope.row,scope.row.content)" size="small" placeholder="结果定义"></el-input>
                                        <el-row v-else>{{scope.row.plan_result}}</el-row>
                                    </template>
                                </el-table-column>
                                <el-table-column label="计划完成" width="200">
                                    <template slot-scope="scope" v-if="scope.row.id != 0">
										<div class="flex weekPlanFinish" v-if="(scope.row.id != 0 && index < showCount) && ((item.status == 0) || (userId && item.review !=1))">
											<p :class="[scope.row.plan_type == 1 && date.value == scope.row.plan_real ? 'cur' : '']" v-for="(date,dateIndex) in weekArr" @click="yujiDate(1,scope.$index,date.value)">{{date.name}}</p>
										</div>
										<div class="flex weekPlanFinish" v-else>
											<p :class="[scope.row.plan_type == 1 && date.value == scope.row.plan_real ? 'cur' : '']" v-for="(date,dateIndex) in weekArr">{{date.name}}</p>
										</div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-row>
                    </el-collapse-item>
                </el-card>
            </el-collapse>
			<div style="margin:50px 0" class="flex">
				<el-button v-if="isWeek==2" size="small" plain type="primary" style="width:50%;" @click="preWeek()">{{title}}</el-button>
				<el-button v-if="isWeek==2" size="small" plain type="primary" style="width:50%;" @click="init()">只看当前周</el-button>
			</div>
            <!-- <el-empty v-if="isWeek==1" description="暂无数据"></el-empty> -->
            <!--留言抽屉-->
            <drawerMessage ref="drawerMessage" ></drawerMessage>
            <!--月报抽屉-->
            <drawerMonth ref="drawerMonth" ></drawerMonth>
        </div>
    </div>
</template>

<script>
import drawerMessage from "@/components/drawerMessage.vue"; //留言抽屉
import drawerMonth from "@/components/drawerMonth.vue"; //月报抽屉
var introJS = require('intro.js')
export default {
    components: {
        drawerMessage,
        drawerMonth
    },
    data() {
        return {
            formData: {},
            weekArray: [], //周报列表
            typeList: [], //岗位职责
            selectWeek: [], //打开id
            clickWeekArray: [],
            rowClickIndex: {}, //点击当前行数据
            clickRow: {},
            colors: ['#008685', '#008685', '#008685'],
            weekDate: ['周一', '周二', '周三', '周四', '周五', '周六', '周日'],
            weekArr: [{
                value: '周一',
                name: '一'
            }, {
                value: '周二',
                name: '二'
            }, {
                value: '周三',
                name: '三'
            }, {
                value: '周四',
                name: '四'
            }, {
                value: '周五',
                name: '五'
            }, {
                value: '周六',
                name: '六'
            }, {
                value: '周日',
                name: '日'
            }],
            weekFinishDate: [{
                id: 1,
                title: '已完成',
                arr: [{
                    id: 1,
                    name: '周一完成'
                }, {
                    id: 1,
                    name: '周二完成'
                }, {
                    id: 1,
                    name: '周三完成'
                }, {
                    id: 1,
                    name: '周四完成'
                }, {
                    id: 1,
                    name: '周五完成'
                }, {
                    id: 1,
                    name: '周六完成'
                }, {
                    id: 1,
                    name: '周日完成'
                }]
            }, {
                id: 2,
                title: '努力中',
                arr: ['10%', '20%', '30%', '40%', '50%', '60%', '70%', '80%', '90%'],
            }, {
                id: 3,
                title: '未开始',
                arr: [{
                    id: 3,
                    name: '下周开始'
                }, {
                    id: 4,
                    name: '任务取消'
                }]
            }, {
                id: 5,
                title: '计划推迟',
            }],

            count: 1,
            showCount: 2, //几条数据可编辑
            progress: '',
            userId: '',
            title: '前一周周报',

            isShowMenu: false, //编辑框状态
            delDistable: true, //删除按钮状态
            sortUpDistable: true, //排序按钮状态
            sortDownDistable: true, //排序按钮状态
            nextWeekDistable: true, //总结转到下周
            isEmpty: false,

            isWeek: '',
            yindao: false,
        }
    },


    watch: {
        $route() {
            this.isShowMenu = false;
            this.isEmpty = false;
            this.init();
        }
    },

    mounted() {
        this.init();
    },

    methods: {
        init: function () {
            this.userId = this.$route.query.id ? this.$route.query.id : '';
            this.count = 1;
            this.getList();
            this.getType();
        },
        setGuide() {
            this.$nextTick(() => {
                introJS().setOptions({
                    prevLabel: "上一步",
                    nextLabel: "下一步",
                    skipLabel: "跳  过",
                    doneLabel: "结束引导",
                    // steps: [{
                    //     element: '#step3',
                    //     intro: '步骤1：'
                    // }, ]
                }).oncomplete(() => {
                    //点击结束按钮后执行的事件
                    this.yindao = true;
                }).onexit(() => {
                    //点击跳过按钮后执行的事件
                    if (!this.yindao) {
                        this.http.post('/admin.user/introIsNo').then(() => {
                            this.$root.userInfo.intro_is = 0;
                        });
                    }
                }).start()
            })
        },

        // 表格岗位职责合并的方法
        handleColspan(list) {
            list.forEach((week) => {
                if (week.done_class_merge == 1) {
                    week.doneColspan = function ({ row, column, rowIndex, columnIndex }) {
                        let spanArr = [] // 页面展示的数据，不一定是全部的数据，所以每次都清空之前存储的 保证遍历的数据是最新的数据。以免造成数据渲染混乱
                        let pos = 0
                        let data = week.done
                        //遍历数据
                        data.forEach((item, index) => {
                            if (index == 0) { //判断是否是第一项（取决于你准备合并表格第几项）
                                spanArr.push(1)
                                pos = 0
                            } else { //不是第一项时，就根据标识去存储
                                if (data[index].class_name === data[index - 1].class_name) { // 查找到符合条件的数据时每次要把之前存储的数据+1
                                    spanArr[pos] += 1
                                    spanArr.push(0)
                                } else { // 没有符合的数据时，要记住当前的index
                                    spanArr.push(1)
                                    pos = index
                                }
                            }
                        })
                        if (columnIndex === 1) {
                            const _row = spanArr[rowIndex]
                            const _col = _row > 0 ? 1 : 0
                            return {
                                rowspan: _row,
                                colspan: _col
                            }
                        } else {
                            return false
                        }
                    }
                }
                if (week.plan_class_merge == 1) {
                    week.planColspan = function ({ row, column, rowIndex, columnIndex }) {
                        let spanArr = [] // 页面展示的数据，不一定是全部的数据，所以每次都清空之前存储的 保证遍历的数据是最新的数据。以免造成数据渲染混乱
                        let pos = 0
                        let data = week.plan
                        //遍历数据
                        data.forEach((item, index) => {
                            if (index == 0) { //判断是否是第一项（取决于你准备合并表格第几项）
                                spanArr.push(1)
                                pos = 0
                            } else { //不是第一项时，就根据标识去存储
                                if (data[index].class_name === data[index - 1].class_name) { // 查找到符合条件的数据时每次要把之前存储的数据+1
                                    spanArr[pos] += 1
                                    spanArr.push(0)
                                } else { // 没有符合的数据时，要记住当前的index
                                    spanArr.push(1)
                                    pos = index
                                }
                            }
                        })
                        if (columnIndex === 1) {
                            const _row = spanArr[rowIndex]
                            const _col = _row > 0 ? 1 : 0
                            return {
                                rowspan: _row,
                                colspan: _col
                            }
                        } else {
                            return false
                        }
                    }
                }
            })
        },

        //周报列表
        getList: function () {
            this.http.post('/admin.work/getList', {
                uid: this.userId,
                count: this.count
            }).then(response => {
                this.formData = response;
                this.isWeek = response.list.length == 0 ? 1 : 2;
                this.weekArray = response.list;
                // 表格岗位职责合并
                this.handleColspan(this.weekArray);
                //el-collapse绑定默认值展开还是关闭
                this.editSelectWeek();

                for (let index in response.list) {
                    //存在下级只能查看不能添加
                    if (((!this.userId && response.list[index].status == 0) || (this.userId && response.list[index].review != 1 && response.list[index].status == 1))) {
                        //当前周数组添加一行空数据进行点击添加操作
                        this.weekArray[index].done.push({ id: 0 })
                        this.weekArray[index].plan.push({ id: 0 })
                    }
                }
                //计划完成时间popover初始化状态
                this.popoverDisabled();

                // 引导
                if (this.$root.userInfo.intro_is == 1) {
                    this.setGuide();
                }
            })
        },

        //分类列表
        getType: function () {
            this.http.post('/admin.work/getClass', {
                uid: this.userId
            }).then(response => {
                this.typeList = response;
            })
        },

        //el-collapse绑定默认值展开还是关闭
        editSelectWeek: function () {
            this.selectWeek = [];
            this.weekArray.forEach((item, index) => {
                this.selectWeek.push(index)
            });
        },

        //popover赋值
        popoverDisabled: function () {
            this.weekArray.forEach((item) => {
                item.done.forEach((zItem) => {
                    this.$set(zItem, 'popVisible', false);
                    this.$set(zItem, 'popFinishVisible', false)
                });
                item.plan.forEach((zItem) => {
                    this.$set(zItem, 'popVisible', false);
                });
            })
        },

        //前一周周报
        preWeek: function () {
            if (this.isEmpty) return;
            this.count += 1;
            this.http.post('/admin.work/show', { weekCount: this.count }).then(response => {
                if (!response) {
                    this.$message.error('前一周暂无数据');
                    this.isEmpty = true;
                    return;
                };
                // 表格岗位职责合并
                this.handleColspan([response]);
                this.weekArray.push(response);
                this.editSelectWeek();
            })
        },

        /**
         * 选择计划完成时间
         * param type 计划完成的类型
         * param rowIndex 选择计划完成当前行的索引index
         * param value 选择计划完成的值
         */
        yujiDate: function (type, rowIndex, value) {
            setTimeout(() => {
                if (this.clickWeekArray[rowIndex].content) {
                    //计划完成类型
                    this.$set(this.clickWeekArray[rowIndex], 'plan_type', type);
                    //计划完成内容
                    this.$set(this.clickWeekArray[rowIndex], 'plan_real', value);

                    this.save(this.clickRow, type);
                } else {
                    this.$message.warning('请填写工作安排');
                }
            }, 100)
        },

        //本周总结选择计划推迟日期
        planSelectDate: function (row) {
            //控制popover显示隐藏
            this.clickWeekArray[row.index].popFinishVisible = false;
            row.done_real = '';
            row.done_real = row.zhuan_date ? row.zhuan_date : this.clickRow.done_real;
            //实际完成类型
            this.$set(this.clickWeekArray[row.index], 'done_type', 5);
            this.save(this.clickRow, row.done_real);
        },

        /**
         * 选择实际完成
         * param type 实际完成的类型
         * param rowIndex 选择总结当前行的索引index
         * param value 选择实际完成的值
         */
        finishDate: function (type, rowIndex, value) {
            //控制popover显示隐藏
            this.clickWeekArray[rowIndex].popFinishVisible = false;
            //实际完成类型
            this.$set(this.clickWeekArray[rowIndex], 'done_type', type);
            //实际完成内容
            this.$set(this.clickWeekArray[rowIndex], 'done_real', value);
            //保存
            this.save(this.clickRow, type)
        },

        //选择分类
        selectTypeBlur: function (param, value, type, index) {
            param.class_name = value;
            this.rowClickIndex.index = index;
            this.save(param, param.class_name, type);
        },

        //行赋值index索引值
        tableRowAddIndex: function ({
            row,
            rowIndex
        }) {
            row.index = rowIndex;
        },

        //设置优先级序号添加样式
        cellStyle: function ({
            row,
            column,
            rowIndex,
            columnIndex
        }) {
            //优先级高亮
            if (row.priority == 2) {
                if (columnIndex == 0) {
                    return 'active'
                } else {
                    return 'activeFC'
                }
            }
            //上级添加总结计划
            if (row.add_status == 2 || row.add_status == 3) {
                if (columnIndex == 0) {
                    return 'reviewColorTable'
                } else {
                    return 'reviewColorTableFC'
                }
            }

            //审核拒绝再次添加总结计划高亮显示
            if (row.add_type == 2) {
                return 'addHighColor'
            }
        },

        //设置优先级数据
        setPriority: function () {
            this.http.post('/admin.work/priority', {
                id: this.clickRow.id,
                type: this.rowClickIndex.name,
                priority: this.clickRow.priority == 1 ? 2 : 1
            }).then(() => {
                this.isShowMenu = false;
                this.getList();
            })
        },

        //结转下周
        nextWeek: function () {
            this.$confirm(`确定结转下周吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.http.post('/admin.work/nextWeek', {
                    id: this.clickRow.id
                }).then(() => {
                    this.$message.success('已转为下周计划！');
                    this.getList();
                })
            })
        },

        //点击本周总结行
        rowClick(row, column, event, index, name) {
            //点击表格行
            this.clickRow = row;
            //点击周
            this.clickWeekArray = this.weekArray[index][name];

            //判断当前周显示编辑框
            this.isShowMenu = (index < this.showCount && this.weekArray[index].status == 0 && !this.clickRow.plan_id) || (this.userId && this.weekArray[index].review != 1) ? true : false;

            //点击当前行的索引值
            this.rowClickIndex = {
                index: index, //点击周的索引
                rowIndex: row.index, //点击总结索引
                name: name
            };

            //菜单栏判断
            this.menuDistable();

            //点击最后一行添加一条新数据
            if (row.id == 0) {
                let object = { class_name: '', content: '', plan_real: '', plan_type: 0, done_real: '', done_type: 0, remark: '', up_mark: 0 }
                this.clickWeekArray.splice(this.clickWeekArray.length - 1, 0, object);
            }
        },

        //菜单按钮判断
        menuDistable: function () {
            let listTemp = this.clickWeekArray.slice(0, -1);

            //删除按钮
            if (!this.clickRow.class_name && !this.clickRow.done_type && !this.clickRow.content && !this.clickRow.plan_type && !this.clickRow.remark) {
                this.delDistable = true;
                this.sortUpDistable = true;
                this.sortDownDistable = true;
                this.nextWeekDistable = true;
            } else {
                this.delDistable = false;
                //向上排序按钮状态
                this.sortUpDistable = this.clickRow.index > 0 ? false : true;
                //向下排序按钮状态
                this.sortDownDistable = this.clickRow.index < listTemp.length - 1 ? false : true;
                //结转下周
                this.nextWeekDistable = this.rowClickIndex.name == 'done' ? false : true;
            }
        },

        // 排序向上
        sortUp: function () {
            if (this.clickRow.index > 0) {
                //复制
                var copy = JSON.parse(JSON.stringify(this.clickRow));
                //删除
                this.clickWeekArray.splice(this.clickRow.index, 1);
                //插入
                this.clickWeekArray.splice(this.clickRow.index - 1, 0, copy);

                this.$refs[this.rowClickIndex.name][0].setCurrentRow(copy)

                this.sortRequest(this.clickWeekArray);

                this.clickRow['index'] = this.clickRow.index - 1;
                //菜单按钮状态
                this.menuDistable();
            }
        },

        // 排序向下
        sortDown: function () {
            let listTemp = this.clickWeekArray.slice(0, -1); //去除最后添加的一行表格
            if (this.clickRow.index < listTemp.length - 1) {
                //复制
                var copy = JSON.parse(JSON.stringify(this.clickRow));
                //删除
                this.clickWeekArray.splice(this.clickRow.index, 1);
                //插入
                this.clickWeekArray.splice(this.clickRow.index + 1, 0, copy);

                this.$refs[this.rowClickIndex.name][0].setCurrentRow(copy);
                //调接口
                this.sortRequest(this.clickWeekArray);
                //排序后更改索引值
                this.clickRow['index'] = this.clickRow.index + 1;

                this.menuDistable();
            }
        },

        //排序接口
        sortRequest: function (list) {
            var submitList = [];
            for (var i in list) {
                if (list[i].id && list[i].id != 0) {
                    submitList.push({
                        sort: i,
                        id: list[i].id,
                    });
                }
            }

            this.http.post('/admin.work/sort', {
                type: this.rowClickIndex.name,
                sort: submitList
            }, false)
        },

        //删除总结or计划
        handleDel: function () {
            this.$confirm(`删除后不可恢复，确定删除吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                //删除选中当前行
                this.clickWeekArray.splice(this.rowClickIndex.rowIndex, 1);
                if (!this.clickRow.id) {
                    this.isShowMenu = false;
                    return
                };
                this.http.post('/admin.work/delete', {
                    id: this.clickRow.id,
                    type: this.rowClickIndex.name == 'done' ? 1 : 2,
                    uid: this.userId
                }).then(() => {
                    this.$message.success('操作成功！');
                    this.isShowMenu = false;
                    this.getList();
                })
            })
        },

        //点击查看月报
        // openMonth: function (item) {

        //     //显示留言抽屉
        //     this.$refs.drawerMonth.open();
        //     //传递参数
        //     this.$refs.drawerMonth.userId = this.userId;

        //     this.$refs.drawerMonth.begin_time = item.begin_time;
        //     //调取留言数据
        //     this.$refs.drawerMonth.getMonth();
        // },

        //通过审核
        acceptCheck: function (item) {
            this.$confirm(`审核通过内容不可修改，确定审核通过吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.checkRequest(1, '', item)
            })
        },

        //拒绝审核
        refuseCheck: function (item) {
            this.$prompt('请输入拒绝原因', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputPattern: /\S/,
                inputErrorMessage: '请输入拒绝原因'
            }).then(({
                value
            }) => {
                this.checkRequest(2, value, item);
            }).catch(() => {
                this.$message('取消输入');
            });
        },

        //审核接口
        checkRequest: function (state, value, item) {
            this.http.post('/admin.work/submitUpd', {
                uid: this.userId,
                review: state,
                refuse_reason: value,
                date: item.begin_time
            }).then(() => {
                this.$message.success('提交成功');
                this.isShowMenu = false;
                this.getList();
            })
        },

        //跳转月报
        jumpMonth: function () {
            this.$router.push({
                path: '/month',
                query: {
                    id: this.$route.query.id
                }
            })
        },

        //实时保存数据
        save: function (param, content, type) {
            param['type'] = type ? type : this.rowClickIndex.name;
            param['uid'] = this.userId;
            param['date'] = this.weekArray[this.rowClickIndex.index].begin_time;
            if (!content) return;
            this.http.post('/admin.work/save', param, false).then(res => {
                param['id'] = res.id;
                //选择未完成更新下周计划
                if (param.type == 'done') {
                    this.weekArray[this.rowClickIndex.index].plan = res.list;
                    this.weekArray[this.rowClickIndex.index].plan.push({ id: 0 });
                }
            })
        },

        //提交周报
        submit: function (param) {
            let weekDone = param.done.slice(0, -1).filter(i => (i.class_name || i.content || i.plan_type || i.done_type));//去除最后一条
            let weekPlan = param.plan.slice(0, -1).filter(i => (i.class_name || i.content || i.plan_result || i.plan_type));//去除最后一条
            //本周总结
            if (weekDone.length == 0) return this.$message.error('请填写本周总结！');
            if (weekPlan.length == 0) return this.$message.error('请填写下周计划！')
            if (weekDone.length != weekDone.filter(i => i.class_name).length) return this.$message.error('本周总结岗位职责不得为空！');
            if (weekDone.length != weekDone.filter(i => i.content).length) return this.$message.error('本周总结工作安排不得为空！');
            if (weekDone.length != weekDone.filter(i => i.plan_type).length) return this.$message.error('本周总结计划完成时间不得为空！');
            //if (weekDone.length != weekDone.filter(i => i.done_type).length) return this.$message.error('本周总结计划完成时间不得为空！');
            //下周计划
            if (weekPlan.length != weekPlan.filter(i => i.class_name).length) return this.$message.error('下周计划岗位职责不得为空！');
            if (weekPlan.length != weekPlan.filter(i => i.content).length) return this.$message.error('下周工作安排不得为空！');
            if (weekPlan.length != weekPlan.filter(i => i.plan_result).length) return this.$message.error('结果定义不得为空！');
            if (weekPlan.length != weekPlan.filter(i => i.plan_type).length) return this.$message.error('下周计划计划完成时间不得为空！');

            this.$confirm(`提交后不可修改，确定提交吗?`, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.http.post('/admin.work/submitSave', { uid: this.userId, date: param.begin_time }).then(() => {
                    this.$message.success('周报提交成功！');
                    this.isShowMenu = false;
                    this.getList();
                })
            }).catch(() => { })
        }
    }
}
</script>
<style media="screen" lang="less">
.el-table .active {
    width: 3px;
    height: 100%;
    background: #ff7300 !important;
    color: #fff !important;
}
.el-table .activeFC {
    color: #ff7300 !important;
    input,
    textarea {
        color: #ff7300 !important;
    }
}

.selectActive {
    background: #e5f3f3;
}

.topPlanActive {
    color: rgba(179, 0, 4, 1);
    font-weight: bold;
    font-size: 14px;
}

.reviewColor {
    color: #008685;
    font-weight: bold;
    font-size: 14px;
}

.reviewColorTable {
    background: #3aafff !important;
    color: #fff !important;
}
.reviewColorTableFC {
    color: #3aafff !important;
    input,
    textarea {
        color: #3aafff !important;
    }
}

.addHighColor {
    color: #006666;
}
.typeWidth {
    width: 100%;
}
</style>
